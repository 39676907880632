export const store = [
	{
		name: 'mattruoca3', color: 'lightgreen', position: [0, 0, 0],  rotation: [0, 0.1, 0],
		url: 'imagea3/ChinhDien.jpg', link: 0,
		arrows: [
			{ position: [-1.69, -0.53, 79.91], link: 1, preview: 'imagea3/sanhtang1.jpg', ad: 'Sảnh a3', type: "vaosanhtang1" },// Link to Sanha3.jpg
			{ position: [75, -10, 10], preview: 'imageLibary/library.jpg', ad: 'I love Hdu' },
			{ position: [-1.69, -13, -79.91], preview: 'imagea6/ChinhDien.jpg', ad: 'Nhà A6' },
			{ position: [-75, -10, 10] , preview: 'imageA1/insiteA1.jpg' , ad: 'Nhà A1'}
		]
	},
	{
		name: 'sanhtang1', color: 'lightblue', position: [0, 0, 0], rotation: [0, 30, 0],
		url: 'imagea3/sanhtang1.jpg', link: 0,
		arrows: [
			{ position: [-60, -10, 110], link: 2, preview: 'imagea3/cauthang1.jpg', ad: 'Cầu thang', type: "sangtang1lencauthang" }, // Link to itself or another scene
			{ position: [-90, -65, -260], link: 0, preview: 'imagea3/ChinhDien.jpg', ad: 'Tòa a3', type: "sangtang1rangoai" } , // Link to another scene if needed
			{ position: [20, -105, 260], link: 5, preview: 'imagea3/KhuonvienA3.jpg', ad: 'Khuôn viên A3', type: "KhuonvienA3" }
		]
	},
	{
		name: 'cauthang', color: 'lightpink', position: [0, 0, 0],  rotation: [0, 4.5, 0],
		url: 'imagea3/cauthang1.jpg', link: 0,
		arrows: [
			{ position: [30.83, 0.88, 66.18], link: 3, preview: 'imagea3/lab208.jpg', ad: 'Lab 208', type: "cauthangvaolab208" }, // Link to CauThang.jpg
			{ position: [-50.14, -13.53, 76.41], link: 4, preview: 'imagea3/sanhtang2.jpg', ad: 'Sảnh tầng 2', type: "cauthangvaosanhtang2" },  // Link to another scene if needed
			{ position: [-60.79, -30.31, -5.37], link: 1, preview: 'imagea3/sanhtang1.jpg', ad: 'Đi xuống', type: "cauthangxuongsanhtang1" }  // Link to another scene if needed
		]
	},
	{
		name: 'lab208', color: 'lightblue', position: [0, 10, 10], rotation: [0, -1.5, 0],
		url: 'imagea3/lab208.jpg', link: 0,
		arrows: [
			//{ position: [-5, 5, -5], link: 0, preview: 'CauThang.jpg', ad: 'Cầu thang' }, // Link to itself or another scene
			{ position: [390.86,-120.03,-54.91], link: 2, preview: 'imagea3/cauthang.jpg', ad: 'Ra ngoài', type: "lab208rangoai" },  // Link to another scene if needed
		],
		equipment: [
			{ id: 1, position: [65.83,13.23,59.18], info: 'Tivi 65 inch sử dụng trình chiếu, báo cáo.' },
			{ id: 2, position: [-300 , 55.07 ,300.34], info: 'Máy tính Dell cấu hình cao.' },
		]
	},
	{
		name: 'sanhtang2', color: 'lightblue', position: [5, -10, -5],rotation: [0, -1.1, 0],
		url: 'imagea3/sanhtang2.jpg', link: 0,
		arrows: [
			{ position: [-150.21,-40.36, 48.80], link: 3, preview: 'imagea3/lab208.jpg', ad: 'Lab 208', type: "sanhtang2vaolab208" }, // Link to itself or another scene
			{ position: [-22.49, -11.89, 26.47], link: 2, preview: 'imagea3/cauthang.jpg', ad: 'Cầu thang', type: "sanhtang2xuongcauthang" },  // Link to another scene if needed
			{ position: [-50.72, -50.07, -78.29], link: 7, preview: 'imagea3/vpk.jpg', ad: 'Văn phòng khoa', type: "sanhtang2vaovpk" }  // Link to another scene if needed
		]
	},
	{
		name: 'KhuonVien', color: 'lightblue', position: [0, 10, 10], rotation: [0, 3.7, 0],
		url: 'imagea3/KhuonvienA3.jpg', link: 0,
		arrows: [
			{ position: [72.19, 2.57, -24.39], link: 2, preview: 'imagea3/sanhtang1.jpg', ad: 'Khoa CNTT&TT', type: "a3202denkhoacntt" }, // Link to itself or another scene
			{ position: [9.66, -63.44, 150.67], link: 6, preview: 'imagea3/giangduong.jpg', ad: 'Giảng đường', type: "a3202vaogiangduong" }  // Link to another scene if needed
		]
	},
	{
		name: 'giangduong', color: 'lightblue', position: [20, 10, 0], rotation: [0, 0, 0],
		url: 'imagea3/giangduong.jpg', link: 0,
		arrows: [
			{ position: [52.52,-19.37,-0.64], link: 5, preview: 'imagea3/a3202.jpg', ad: 'Ra ngoài', type: "giangduongrangoai" }, // Link to itself or another scene
		]
	},
	{
		name: 'vpk', color: 'lightblue', position: [0, -5, 0], rotation: [0, -2.7, 0],
		url: 'imagea3/vpk.jpg', link: 0,
		arrows: [
			{ position: [40.77,-32.74,-38.33], link: 4, preview: 'imagea3/vpk.jpg', ad: 'Ra ngoài', type: "vpkrangoai" }, // Link to itself or another scene
		]
	},
];
