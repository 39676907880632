import * as THREE from 'three';
import React, { useRef, useState, useContext, useEffect, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import { Canvas, useLoader } from '@react-three/fiber';
import { Html, Preload, OrbitControls } from '@react-three/drei';
import { AutoRotateContext } from '../../Component/pauseState/AutoRotateContext';
import LoadingArrow from '../LoadingArrow/LoadingArrow';
import '../A6/styles.css';

const store = [
  {
    name: 'ChinhDien', vitri: "ChinhDien", position: [0, 0, 0],
    url: 'imagea6/ChinhDien.jpg',
    arrows: [
      { position: [205, -70, -20], link: 1, preview: 'imagea6/Insite.jpg', ad: 'Insite ', }, // Link to itself or another scene
      { position: [-100, -20, 10], preview: 'imagea3/ChinhDien.jpg', ad: 'Nhà a3' },
      { position: [10, -40, 250], preview: 'imagea5/sanhA5.jpg', ad: 'Nhà a5' },
      { position: [10, -40, -250], preview: 'imageA7/sanhA7.jpg', ad: 'Nhà A7' },
    ]
  },
  {
    name: 'Insite', vitri: "Insite", position: [50, 10, 10],
    url: 'imagea6/Insite.jpg',
    arrows: [
      { position: [-350, -50, -150], link: 0, preview: 'imagea6/ChinhDien.jpg', ad: 'Chính Diện', },
      { position: [50, -50, 150], link: 2, preview: 'imagea6/RoomStudy.jpg', ad: 'Phòng máy A6', },
      { position: [250, -50, -250], preview: 'imageA7/sanhA7.jpg', ad: 'Nhà A7', },
    ]
  },
  {
    name: 'Room', vitri: "Room", position: [50, 10, 10],
    url: 'imagea6/RoomStudy.jpg',
    arrows: [
      { position: [350, -50, 30], link: 1, preview: 'imagea6/Insite.jpg', ad: 'Khuôn viên A6', },
    ]
  },
];

function Dome({ name, vitri, position, texture, onClick, arrows, controlsRef }) {
  const [hovered, setHovered] = useState(null);
  const { autoRotate, setAutoRotate } = useContext(AutoRotateContext);
  const navigate = useNavigate();

  const handleArrowClick = (arrow) => {
    if (arrow.position[0] === -100 && arrow.position[1] === -20 && arrow.position[2] === 10) {
      navigate('/a3');
    } else if (arrow.position[0] === 10 && arrow.position[1] === -40 && arrow.position[2] === 250) {
      navigate('/a5')
    } else if (arrow.position[0] === 250 && arrow.position[1] === -50 && arrow.position[2] === -250) {
      navigate('/a7')
    }
    else if (arrow.position[0] === 10 && arrow.position[1] === -40 && arrow.position[2] === -250) {
      navigate('/a7')
    }
    else {
      onClick(arrow.link);
    }
  };

  return (
    <group>
      <mesh scale={1} rotation={[0, 0, 0]} position={position}>
        <sphereGeometry args={name === 'iloveHdu' ? [800, 60, 1000] : [100, 100, 90]} />
        <meshBasicMaterial map={texture} side={THREE.BackSide} />
      </mesh>
      {arrows.map((arrow, index) => (
        <mesh key={index} position={arrow.position}>
          <Html position={arrow.position} style={{ transform: 'translate(-50%, -50%)' }}>
            <div
              onClick={() => handleArrowClick(arrow)}
              onMouseEnter={() => {
                setHovered(index);
                setAutoRotate(false);
              }}
              onMouseLeave={() => {
                setHovered(null);
                setAutoRotate(true);
              }}
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            >
              <img className='arrowIcon' src='https://vr360.com.vn/projects/dai-hoc-ngan-hang-tp-hcm/assets/Move/up.png' alt='' />
              {hovered === index && (
                <div
                  style={{
                    display: 'flex',
                    position: 'absolute',
                    justifyContent: 'center',
                    alignItems: 'center',
                    top: '-200px',
                    width: '200px',
                    height: '200px',
                    backgroundColor: 'rgba(243, 113, 37, 0.9)',
                    borderRadius: '10%',
                  }}
                >
                  <div style={{ justifyContent: 'center' }}>
                    <img src={arrow.preview} alt='' style={{ width: '185px', height: '135px', borderRadius: '10%' }} />
                    <p style={{ textAlign: 'center', color: 'white', fontWeight: 'bold' }}>{arrow.ad}</p>
                  </div>
                </div>
              )}
            </div>
          </Html>
        </mesh>
      ))}
    </group>
  );
}

function Portals({ controlsRef }) {
  const { autoRotate, setAutoRotate } = useContext(AutoRotateContext);
  const [which, set] = useState(0); // Set initial state to 1 to start with Toaa3.jpg
  const { link, ...props } = store[which];
  const [loading, setLoading] = useState(false);
  const handleChangeScene = (newLink) => {
    setLoading(true);
    setTimeout(() => {
      set(newLink);
      setLoading(false);
    }, 500);
  };

  const maps = useLoader(THREE.TextureLoader, store.map((entry) => entry.url)); // prettier-ignore

  return (
    <>
      {loading && (
        <Html>
          <LoadingArrow />
        </Html>
      )}
      <Dome
        onClick={handleChangeScene}
        {...props}
        texture={maps[which]}
        arrows={store[which].arrows}
        controlsRef={controlsRef}
      />
    </>
  );
}

function StudyTogether() {
  const { autoRotate, autoRotateSpeed } = useContext(AutoRotateContext);
  const controlsRef = useRef();

  useEffect(() => {
    // Ensure controlsRef.current is not undefined
    if (controlsRef.current) {
      // You can log controlsRef.current to debug if needed
      console.log(controlsRef.current);
    }
  }, [controlsRef]);

  return (
    <Canvas frameloop='demand' camera={{ position: [-20, 0, 0.1] }} style={{ cursor: 'url(https://marriott-f0bec.web.app/danphuong_hamo/lib/cursors/grab.cur), default' }}>
      <OrbitControls
        ref={controlsRef}
        minDistance={1} // Giới hạn thu nhỏ
        maxDistance={10}
        enableZoom={true}
        enablePan={false}
        enableDamping
        dampingFactor={0.2}
        autoRotate={autoRotate}
        rotateSpeed={-0.5}
        autoRotateSpeed={autoRotateSpeed}
      />
      <Suspense fallback={null}>
        <Preload all />
        <Portals
          controlsRef={controlsRef}
          setAutoRotate={autoRotate}
          autoRotateSpeed={autoRotateSpeed}
        />
      </Suspense>
    </Canvas>
  );
}

export default StudyTogether;
