import React, { useContext, useState } from 'react';

import { Canvas, } from '@react-three/fiber';
import { Html, OrbitControls, } from '@react-three/drei';
import { useTexture } from '@react-three/drei';

import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons'
import { Button, Divider, Flex, Radio } from 'antd'

import '../../styless.css';
import '../Homepage/styleMap.css'

import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';

const store = [
    { name: "Nhà học chung", url: 'image.png', position: [-3.6, 0.2, 0], link: '/a6' },
    { name: "Khoa Kỹ thuật, công nghệ và truyền thông", url: 'image.png', position: [0.8, 0.6, -1.7], link: '/a3' },
    { name: "Khoa Nông lâm - Ngư nghiệp", url: 'image.png', position: [0, -0.6, 0], link: '/a1' },
    { name: "Khoa Kinh tế", url: 'image.png', position: [1.5, -0.3, 0], link: '/a2' },
    { name: "Kí túc xá", url: 'image.png', position: [3.2, -0.1, 0], link: '/ki-tuc-xa' },
    { name: "Thư viện", url: 'image.png', position: [0, 1.5, 0], link: '/library' },
    { name: "Khoa tự nhiên & xã hội", url: 'image.png', position: [-2.4, 1.5, 0], link: '/a5' },
    { name: "Khoa thể chất", url: 'image.png', position: [-3.4, 2.5, 0], link: '/giao-duc-the-chat' },
    { name: "Nhà điều hành", url: 'image.png', position: [-2.8, -0.3, 0], link: '/nha-dieu-hanh' },
    { name: "Hội trường lớn", url: 'image.png', position: [-1.8, -0.9, 0], link: '/hoi-tuong-lon' },
    { name: "Khoa Ngoại ngữ", url: 'image.png', position: [-4.6, -0.8, 0], link: '/a7' },
    { name: "Trường TH, THCS và THPT Hồng Đức", url: 'image.png', position: [-0.2, 2.6, 0], link: '/lien-cap' },
    { name: "Sân bóng Lucky", url: 'image.png', position: [3.5, -1.3, 0], link: '/san-bong-lucky' },
    { name: "Kí túc xá Sinh viên Lào", url: 'image.png', position: [2.3, 2.6, 0], link: '/ki-tuc-xa-lao' },
]

function ImageSvg() {
    return (
        <svg className='local' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32" >
            <path fill="#253372" d="M25.3335 11.9998C25.3335 10.7742 25.092 9.5605 24.623 8.42812C24.154 7.29575 23.4665 6.26685 22.5998 5.40017C21.7331 4.53349 20.7042 3.84601 19.5718 3.37696C18.4395 2.90792 17.2258 2.6665 16.0001 2.6665C14.7744 2.6665 13.5608 2.90792 12.4284 3.37696C11.296 3.84601 10.2671 4.53349 9.40042 5.40017C8.53374 6.26685 7.84625 7.29575 7.3772 8.42812C6.90816 9.5605 6.66675 10.7742 6.66675 11.9998C6.66675 13.8492 7.21208 15.5692 8.14008 17.0198H8.12941L16.0001 29.3332L23.8708 17.0198H23.8615C24.8226 15.5219 25.3335 13.7796 25.3335 11.9998ZM16.0001 15.9998C14.9393 15.9998 13.9218 15.5784 13.1717 14.8283C12.4215 14.0781 12.0001 13.0607 12.0001 11.9998C12.0001 10.939 12.4215 9.92156 13.1717 9.17141C13.9218 8.42126 14.9393 7.99984 16.0001 7.99984C17.061 7.99984 18.0784 8.42126 18.8285 9.17141C19.5787 9.92156 20.0001 10.939 20.0001 11.9998C20.0001 13.0607 19.5787 14.0781 18.8285 14.8283C18.0784 15.5784 17.061 15.9998 16.0001 15.9998Z" />
            <rect fill="white" height="14" rx="7" width="14" y="5" x="9" />
            <g clip-path="url(#clip0_13_145)">
                <path fill="#243571" d="M19.6111 9.22223H17.3889V9.77778H19.6111V15.6111H17.3889V16.1667H20.1667V9.77778C20.1667 9.63044 20.1082 9.48913 20.004 9.38495C19.8998 9.28076 19.7585 9.22223 19.6111 9.22223Z" />
                <path fill="#243571" d="M16.5222 7.83331H12.7C12.5438 7.83331 12.394 7.89536 12.2836 8.00579C12.1731 8.11623 12.1111 8.26602 12.1111 8.4222V16.1666H17.1111V8.4222C17.1111 8.26602 17.049 8.11623 16.9386 8.00579C16.8282 7.89536 16.6784 7.83331 16.5222 7.83331ZM16.5555 15.6111H15.7222V14.7778H13.5V15.6111H12.6666V8.4222C12.6666 8.41782 12.6675 8.41349 12.6692 8.40945C12.6709 8.4054 12.6733 8.40173 12.6764 8.39863C12.6795 8.39554 12.6832 8.39308 12.6872 8.39141C12.6913 8.38973 12.6956 8.38887 12.7 8.38887H16.5222C16.5266 8.38887 16.5309 8.38973 16.535 8.39141C16.539 8.39308 16.5427 8.39554 16.5458 8.39863C16.5489 8.40173 16.5513 8.4054 16.553 8.40945C16.5547 8.41349 16.5555 8.41782 16.5555 8.4222V15.6111Z" />
                <path fill="#243571" d="M13.2222 9.22223H13.7777V9.77778H13.2222V9.22223Z" />
                <path fill="#243571" d="M14.3334 9.22223H14.8889V9.77778H14.3334V9.22223Z" />
                <path fill="#243571" d="M15.4445 9.22223H16V9.77778H15.4445V9.22223Z" />
                <path fill="#243571" d="M13.2222 10.6111H13.7777V11.1666H13.2222V10.6111Z" />
                <path fill="#243571" d="M14.3334 10.6111H14.8889V11.1666H14.3334V10.6111Z" />
                <path fill="#243571" d="M15.4445 10.6111H16V11.1666H15.4445V10.6111Z" />
                <path fill="#243571" d="M13.2222 12H13.7777V12.5556H13.2222V12Z" />
                <path fill="#243571" d="M14.3334 12H14.8889V12.5556H14.3334V12Z" />
                <path fill="#243571" d="M15.4445 12H16V12.5556H15.4445V12Z" />
                <path fill="#243571" d="M13.2222 13.3889H13.7777V13.9445H13.2222V13.3889Z" />
                <path fill="#243571" d="M14.3334 13.3889H14.8889V13.9445H14.3334V13.3889Z" />
                <path fill="#243571" d="M15.4445 13.3889H16V13.9445H15.4445V13.3889Z" />
                <path fill="#243571" d="M17.3889 10.6111H17.9445V11.1666H17.3889V10.6111Z" />
                <path fill="#243571" d="M18.5 10.6111H19.0556V11.1666H18.5V10.6111Z" />
                <path fill="#243571" d="M17.3889 12H17.9445V12.5556H17.3889V12Z" />
                <path fill="#243571" d="M18.5 12H19.0556V12.5556H18.5V12Z" />
                <path fill="#243571" d="M17.3889 13.3889H17.9445V13.9445H17.3889V13.3889Z" />
                <path fill="#243571" d="M18.5 13.3889H19.0556V13.9445H18.5V13.3889Z" />
            </g>
            <defs>
                <clipPath id="clip0_13_145">
                    <rect fill="white" transform="translate(11 7)" height="10" width="10" />
                </clipPath>
            </defs>
        </svg>
    );
}

function ImageMesh({ scale }) {
    // Tải ảnh làm texture cho lưới
    const texture = useTexture('map_Hdu_Uni.jpg'); // Thay thế đường dẫn ảnh của bạn ở đây
    const imgLocalisation = useTexture('localtion.png')
    const [hovered, setHovered] = useState(null);
    // Trả về một lưới (mesh) với vật liệu là texture
    return (
        <mesh scale={scale} position={[0, 0, 0]} zIndex={[1]} > {/* Điều chỉnh vị trí nếu cần */}
            <planeGeometry args={[15, 9]} /> {/* Điều chỉnh kích thước mặt phẳng nếu cần */}
            <meshBasicMaterial attach="material" map={texture} />
            {store.map((hover, index) => (
                <Html key={index} position={hover.position} scale={[0.5, 0.5, 0.5]} className="resizable-box">
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <a
                            href={hover.link}
                            onMouseEnter={() => {
                                setHovered(index);
                            }}
                            onMouseLeave={() => {
                                setHovered(null);
                            }}
                            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textDecoration: 'none' }}>
                            <ImageSvg />
                            {hovered === index && (
                                <div
                                    style={{
                                        display: 'flex',
                                        position: 'absolute',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        top: '-200px',
                                        width: '200px',
                                        height: '200px',
                                        backgroundColor: '#253372',
                                        borderRadius: '5%',
                                        zIndex: 1000
                                    }}
                                >
                                    <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                                        <img src={hover.url} alt="Small Image" style={{ width: '180px', height: '130px', borderRadius: '5%' }} />
                                        <p style={{
                                            textAlign: 'center',
                                            color: 'white',
                                            fontWeight: 500,
                                            width: '180px', // Adjust the width to match the image width
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            margin: 0, // To remove any default margin
                                        }}>{hover.name}</p>
                                    </div>
                                </div>)}
                        </a>
                    </div>
                </Html>
            ))}
        </mesh>
    );
}

function Home({ onZoomChange }) {
    const [imageMeshScale, setImageMeshScale] = useState(1.15);

    return (
        // <div style={{ width: '100%', height: '100%' }}>
            <Canvas style={{ width: '100%', height: '100%' }}>
                <OrbitControls
                    enableRotate={false}
                    enableZoom={false} // Disable default zoom behavior because we're controlling it manually
                    maxPolarAngle={0}
                    minPolarAngle={Math.PI / 2}
                    maxAzimuthAngle={Math.PI / 10}
                    minAzimuthAngle={-Math.PI / 10}
                />
                <ImageMesh scale={imageMeshScale} />
            </Canvas>
        // </div>
    );
}

export default Home;