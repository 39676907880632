import { memo, useRef, useState, useEffect } from 'react';

import Header from '../Header';
import Footer from '../Footer';

import { Routes, Route } from 'react-router-dom'

import { URL } from '../../Page/utils/Router';

import Contact from '../Contact/Contact';
import Home from '../Homepage/Home';
import Technology from '../../Page/A3/Technology';
import StudyTogether from '../../Page/A6/StudyTogether';
import Library from '../../Page/Library/Library';
import NotFound from '../../Page/NotFound/NotFond';

import { Slider } from '@mui/material'; // or any other slider component you prefer

import { PlusOutlined, MinusOutlined } from '@ant-design/icons'

import '../MasterLayout/style.css'
import ChatBot from '../ChatBotAI';
import Agroforestry from '../../Page/A1/Agroforestry';
import Economy from '../../Page/A2/Economy.js';
import HomeA5 from '../../Page/A5/homeA5.js';
import PhysicalEducation from '../../Page/PhysicalEducation/Physical.js';
import ForeignLanguage from '../../Page/A7/foreignLanguage.js';
import CenterHome from '../../Page/NhaDieuHanh/index.js';
import HoiTruongLon from '../../Page/HoiTruong/index.js';
import LuckyFootball from '../../Page/LuckyFootball/index.js';
import StudentDormitory from '../../Page/KTX/index.js';
import InterLevel from '../../Page/LienCap/index.js';
import DormitoryOutside from '../../Page/KTXLao/index.js';

function MasterLayout({ children, ...props }) {

    const [zoom, setZoom] = useState(1); // Trạng thái quản lý mức độ zoom
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 }); // Vị trí hiện tại của con trỏ chuột
    const containerRef = useRef(null);
    const headerRef = useRef(null);
    const footerRef = useRef(null);
    const ChatbotRef = useRef(null);

    const handleZoomChange = (event, newValue) => {
        setZoom(newValue);
    };

    const handleMouseMove = (event) => {
        // Cập nhật vị trí hiện tại của con trỏ chuột
        const boundingRect = containerRef.current.getBoundingClientRect();
        const x = event.clientX - boundingRect.left;
        const y = event.clientY - boundingRect.top;
        setMousePosition({ x, y });
    };

    useEffect(() => {
        const handleWheel = (event) => {
            const boundingRect = containerRef.current.getBoundingClientRect();
            const mouseX = event.clientX - boundingRect.left;
            const mouseY = event.clientY - boundingRect.top;

            // Kiểm tra nếu chuột đang ở trên header hoặc footer
            const isMouseOverHeader = headerRef.current.contains(event.target);
            const isMouseOverFooter = footerRef.current.contains(event.target);

            if (isMouseOverHeader || isMouseOverFooter) {
                return;
            }

            if (event.deltaY < 0) {
                // Cuộn chuột xuống, giảm zoom
                setZoom((prevZoom) => Math.max(1, prevZoom - 0.3));
            } else if (event.deltaY > 0) {
                // Cuộn chuột lên, tăng zoom
                setZoom((prevZoom) => Math.min(5, prevZoom + 0.3));
            }

            // Tính toán lại transform-origin dựa trên vị trí của con trỏ chuột
            const originX = `${(mouseX / boundingRect.width) * 100}%`;
            const originY = `${(mouseY / boundingRect.height) * 100}%`;

            containerRef.current.style.transformOrigin = `${originX} ${originY}`;
        };

        // Thêm trình nghe sự kiện cho bánh xe chuột
        window.addEventListener('wheel', handleWheel);

        // Clean up event listener khi component unmount
        return () => {
            window.removeEventListener('wheel', handleWheel);
        };
    }, []);

    const [isShowChat, setIsShowChat] = useState(false);
    return (
        <div style={{ width: '100%', height: '100%' }} {...props}>
            <div ref={headerRef}>
                <Header />
            </div>
            <div className="zoom-controls">
                <div onClick={() => setZoom((prevZoom) => Math.min(5, prevZoom + 0.3))} className="zoom-button zoom-in">
                    <PlusOutlined />
                </div>
                <div className="zoom-slider-container">
                    <Slider
                        value={zoom}
                        color='white'
                        min={1}
                        max={5}
                        step={0.1}
                        onChange={handleZoomChange}
                        aria-labelledby="zoom-slider"
                        valueLabelDisplay="auto"
                        orientation="vertical" // Make the slider vertical
                        className="zoom-slider"
                    />
                </div>
                <div onClick={() => setZoom((prevZoom) => Math.max(1, prevZoom - 0.3))} className="zoom-button zoom-out">
                    <MinusOutlined />
                </div>
            </div>
            <div className='chatBox' onClick={() => setIsShowChat(!isShowChat)}>
                <img src="iconChatBot.png" alt="Chat Icon" className="chat-icon" />
            </div>
            {isShowChat && (
                // <ChatBot ref={ChatbotRef}/>
                <iframe
                ref={ChatbotRef}
                src="https://www.chatbase.co/chatbot-iframe/qVubh1ilShp-lx0Y5r7WA"
                width="100%"
                // style={{ position: 'absolute',width: 438, height: '570.2px' , right: 15 , }}
                frameborder="0"
                ></iframe>
            )}
            <div ref={containerRef} style={{ transform: `scale(${zoom})`, transformOrigin: '0 0', width: '100%', height: '100%' }}>
                {children}
            </div>
            <div ref={footerRef}>
                <Footer />
            </div>
        </div>
    );
}
const renderUserRouter = () => {
    const permis = "user"
    const userRouters = [
        {
            path: URL.HOMEPAGE,
            component: <Home />,
            permission: "all"
        },
        {
            path: URL.CONTACT,
            component: <Contact />,
            permission: "all"
        },
        {
            path: URL.PAGE.A3,
            component: <Technology />,
            permission: "all"
        },
        {
            path: URL.PAGE.A6,
            component: <StudyTogether />,
            permission: "all"
        },
        {
            path: URL.PAGE.LIBRARY,
            component: <Library />,
            permission: "all"
        },
        {
            path: URL.PAGE.A1,
            component: <Agroforestry/>,
            permission: "all",
        },
        {
            path: URL.PAGE.A2,
            component: <Economy/>,
            permission: "all",
        },
        {
            path: URL.PAGE.A5,
            component: <HomeA5/>,
            permission: "all",
        },
        {
            path: URL.PAGE.PHYSICAL,
            component: <PhysicalEducation/>,
            permission: "all",
        },
        {
            path: URL.PAGE.A7,
            component: <ForeignLanguage/>,
            permission: "all",
        },
        {
            path: '*',
            component: <NotFound />,
            permission: "all"
        },
        {
            path: URL.PAGE.CENTER_HOME, 
            component: <CenterHome/>,
            permission: "all"
        },
        {
            path: URL.PAGE.LARGE_HALL,
            component: <HoiTruongLon/>,
            permission: "all"
        }, 
        {
            path: URL.PAGE.FOOTBALL_FIELD,
            component: <LuckyFootball/>,
            permission: "all"
        }, 
        {
            path: URL.PAGE.KI_TUC_XA,
            component: <StudentDormitory/>,
            permission: "all"
        },
        {
            path: URL.PAGE.A4,
            component: <InterLevel/>,
            permission: "all"
        },
        {
            path: URL.PAGE.KI_TUC_XA_LAO,
            component: <DormitoryOutside/>,
            permission: "all"
        }
    ]
    const checkPermission = (permissionComponent, permissionUser) => {
        if (permissionComponent === "all") return true;
        if (permissionComponent === permissionUser) return true;
        else return false;
    }
    const containerRef = useRef(null);
    return (
        <MasterLayout>
            <Routes>
                {
                    userRouters.map((item, index) => {
                        if (checkPermission(item.permission, permis)) {
                            return (
                                <Route key={index} path={item.path} element={item.component}></Route>)
                        }
                    })
                }
            </Routes>
        </MasterLayout>
    );
};
const Routercustom = () => {
    return renderUserRouter();
};

export default Routercustom;