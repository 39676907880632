import * as THREE from 'three';
import React, { useRef, useState, useContext, useEffect, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import { Canvas, useLoader } from '@react-three/fiber';
import { Html, Preload, OrbitControls } from '@react-three/drei';
import { AutoRotateContext } from '../../Component/pauseState/AutoRotateContext';
import LoadingArrow from '../LoadingArrow/LoadingArrow';
import '../A6/styles.css';

const store = [
  {
    name: 'sanhA5', vitri: "sanhA5",  position: [0, 0, 0],
    url: 'imageA5/sanhA5.jpg', 
    arrows: [
      { position: [-105, -70, 200], preview: 'imagea6/ChinhDien.jpg', ad: 'Nhà A6'}, 
      { position: [-105, -20, 10] , preview : 'imageA5/insiteA5.jpg', ad: 'Khuôn viên A5' , link: 1},
      { position: [-105, -70 , -200] , preview: 'Physical/sanhthechat.jpg' , ad: 'Giáo dục thể chất'},
      { position: [105 , -30 , 60] , preview: 'imageLibary/library.jpg' , ad: 'I Love HDU'}
    ]
  },
  {
    name: 'Khuonvien', vitri: "Khuonvien",  position: [0, 0, 0],
    url: 'imageA5/insiteA5.jpg', 
    arrows: [
      { position: [-105, -20, -10] , preview : 'imageA5/sanhA5.jpg', ad: 'Sảnh A5' , link: 0},
      { position: [95, -70 , 270] , preview: 'Physical/sanhthechat.jpg' , ad: 'Giáo dục thể chất'},
      { position: [95, -70, -250], preview: 'imagea6/ChinhDien.jpg', ad: 'Nhà A6'}, 
    ]
  },
];

function Dome({ name, vitri, position, texture, onClick, arrows, controlsRef }) {
  const [hovered, setHovered] = useState(null);
  const { autoRotate, setAutoRotate } = useContext(AutoRotateContext);
  const navigate = useNavigate();

  const handleArrowClick = (arrow) => {
    if (arrow.position[0] === -105 && arrow.position[1] === -70 && arrow.position[2] === 200) {
      navigate('/a6');
    } else if(arrow.position[0] === -105 && arrow.position[1] === -70 && arrow.position[2] === -200){
      navigate('/giao-duc-the-chat')
    }else if(arrow.position[0] === 105 && arrow.position[1] === -30 && arrow.position[2] === 60){
      navigate('/library')
    } else if(arrow.position[0] === 95 && arrow.position[1] === -70 && arrow.position[2] === 270){
      navigate('/giao-duc-the-chat')
    }else if (arrow.position[0] === 95 && arrow.position[1] === -70 && arrow.position[2] === -250) {
      navigate('/a6');
    }
    else {
      onClick(arrow.link);
    }
  };

  return (
    <group>
      <mesh scale={1} rotation={[0, 0, 0]} position={position}>
        <sphereGeometry args={name === 'iloveHdu' ? [800, 60, 1000] : [100, 100, 90]} />
        <meshBasicMaterial map={texture} side={THREE.BackSide} />
      </mesh>
      {arrows.map((arrow, index) => (
        <mesh key={index} position={arrow.position}>
          <Html position={arrow.position} style={{ transform: 'translate(-50%, -50%)' }}>
            <div
              onClick={() => handleArrowClick(arrow)}
              onMouseEnter={() => {
                setHovered(index);
                setAutoRotate(false);
              }}
              onMouseLeave={() => {
                setHovered(null);
                setAutoRotate(true);
              }}
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            >
              <img className='arrowIcon' src='https://vr360.com.vn/projects/dai-hoc-ngan-hang-tp-hcm/assets/Move/up.png' alt='' />
              {hovered === index && (
                <div
                  style={{
                    display: 'flex',
                    position: 'absolute',
                    justifyContent: 'center',
                    alignItems: 'center',
                    top: '-200px',
                    width: '200px',
                    height: '200px',
                    backgroundColor: 'rgba(243, 113, 37, 0.9)',
                    borderRadius: '10%',
                  }}
                >
                  <div style={{ justifyContent: 'center' }}>
                    <img src={arrow.preview} alt='' style={{ width: '185px', height: '135px', borderRadius: '10%' }} />
                    <p style={{ textAlign: 'center', color: 'white', fontWeight:'bold' }}>{arrow.ad}</p>
                  </div>
                </div>
              )}
            </div>
          </Html>
        </mesh>
      ))}
      {/* {name === "sanhA5" && (
				<Html style={{ width: 1500, height: 1000 }} position={[-100, 55, 24]} transform rotation={[ Math.PI / -50, Math.PI / 1.9, Math.PI / 60]} scale={[1, 1, 1]}>
					<video
						width="3000"
						height="1600"
            src='video_truong_Hong_Duc.mp4'
						controls
						autoPlay
						muted
            loop
						title="Video player"
            type="video/mp4"
            style={{ display: 'block', margin: '0 auto', outline: 'none' }} 
					>
					</video>
				</Html>
			)} */}
    </group>
  );
}

function Portals({ controlsRef }) {
  const { autoRotate, setAutoRotate } = useContext(AutoRotateContext);
  const [which, set] = useState(0); // Set initial state to 1 to start with Toaa3.jpg
  const { link, ...props } = store[which];
  const [loading, setLoading] = useState(false);
  const handleChangeScene = (newLink) => {
    setLoading(true);
    setTimeout(() => {
      set(newLink);
      setLoading(false);
    }, 500); // Adjust delay as needed
  };

  const maps = useLoader(THREE.TextureLoader, store.map((entry) => entry.url)); // prettier-ignore

  return (
    <>
      {loading && (
        <Html>
          <LoadingArrow />
        </Html>
      )}
      <Dome onClick={handleChangeScene} {...props} texture={maps[which]} arrows={store[which].arrows} controlsRef={controlsRef} />
    </>
  );
}

function HomeA5() {
  const { autoRotate, autoRotateSpeed } = useContext(AutoRotateContext);
  const controlsRef = useRef();

  useEffect(() => {
    // Ensure controlsRef.current is not undefined
    if (controlsRef.current) {
      // You can log controlsRef.current to debug if needed
      console.log(controlsRef.current);
    }
  }, [controlsRef]);

  return (
    <Canvas frameloop='demand' camera={{ position: [-20, 0, 0.1] }} style={{ cursor: 'url(https://marriott-f0bec.web.app/danphuong_hamo/lib/cursors/grab.cur), default' }}>
      <OrbitControls
        ref={controlsRef}
        minDistance={1} // Giới hạn thu nhỏ
        maxDistance={10}
        enableZoom={true}
        enablePan={false}
        enableDamping
        dampingFactor={0.2}
        autoRotate={autoRotate}
        rotateSpeed={-0.5}
        autoRotateSpeed={autoRotateSpeed}
      />
      <Suspense fallback={null}>
        <Preload all />
        <Portals
          controlsRef={controlsRef}
          setAutoRotate={autoRotate}
          autoRotateSpeed={autoRotateSpeed} 
          />
      </Suspense>
    </Canvas>
  );
}

export default HomeA5;
